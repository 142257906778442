import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="error-page">
      <article>
        <h1>404</h1>
        <h2>We can't seem to find the page you are looking for.</h2>
        <Link to="/">Go back to home</Link>
      </article>
    </div>
  </Layout>
);

export default NotFoundPage;
